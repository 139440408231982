import nextI18next from './i18n';
import { TFunction } from 'i18next';
import { UseTranslation } from 'next-i18next';
import { useCallback } from 'react';

export const useTranslation = (...params: Parameters<UseTranslation>) => {
  const { t, ...rest } = nextI18next.useTranslation(...params);
  const newT: TFunction = useCallback(
    (key: string, options: any) => {
      const keys = key.split('.');
      const transformedKey = `${keys[0]}::${keys.join('.')}`;
      return t(transformedKey, options);
    },
    [t]
  );
  return { t: newT, ...rest };
};
