import NextI18Next from 'next-i18next';

export default new NextI18Next({
  // preventing cookie on browser
  browserLanguageDetection: false,
  defaultLanguage: 'fr',
  detection: {
    cookieSameSite: 'strict',
  },
  keySeparator: ':',
  lng: 'fr',
  localePath: 'src/locales',
  nsSeparator: '::',
  otherLanguages: ['fr'],
  // debug: true,
  serverLanguageDetection: false,
});
